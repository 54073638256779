import React from "react";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";

function CFooter() {
  AOS.init({
    easing: "ease-in-out",
    once: false,
    mirror: true,
  });
  const { t, i18n } = useTranslation();
  return (
    <div className="h-20px text-center">
     <p className="bg-blue-800 text-white p-5 text-lg lg:text-3xl font-bold">{t('Bole Sub-City Administration Trade Office')}</p>
    </div>
  );
}

export default CFooter;
