import React from "react";
import HammerBanner from "../../assets/images/tradeb.jpg";
import JudicialServicesImg from "../../assets/images/1.jpg"; // Example image paths
import CourtProceduresImg from "../../assets/images/CapacityBuilding.jpg";
import CourtProceduresImg2 from "../../assets/images/Maintenance.jpg";
import LegalAssistanceImg from "../../assets/images/SoftDev.jpg";
import OnlineServicesImg from "../../assets/images/TradeInspection.webp";
import SupportServicesImg from "../../assets/images/CustomerRight.jpg";
import ContactInfoImg from "../../assets/images/6.jpg";
import DownloadsResourcesImg from "../../assets/images/services.jpg";
import FeedbackSupportImg from "../../assets/images/Feedback.png";
import NoticesAlertsImg from "../../assets/images/Info2.png";
import JudicialServicesDetailImg from "../../assets/images/Data Center.webp"; // Example detail image path
import { useTranslation } from "react-i18next";
function ServicesPage() {
  const { t, i18n } = useTranslation();
  const sections = [
    {
      title: t('The main services provided by Innovation Technology Infrastructure and Data Center Management Group'),
      description: (
        <>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
            <li>{t('Data Center Facility Management')}</li>
            <li>{t('System management')}</li>
            <li>{t('E-mail service')}</li>
            <li>{t('Active Directory Service')}</li>
            <li>{t('Active Directory Service Video Conference Service')}</li>
            <li>{t('Network management')}</li>
            <li>{t('Internet service management')}</li>
            <li>{t('Information technology system security and monitoring')}</li>
          </ul>
        </>
      ),
      img: JudicialServicesDetailImg,
    },
    {
      title: t('Capacity building team'),
      description: (
        <>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
            <li>{t('Provide internal training')}</li>
            <li>{t('Providing long-term and short-term training in cooperation')}</li>
            <li>
            {t('Individuals with different creative work are officially invited by institutions')}
            </li>
            <li>
            {t('Identification of innovation technology and innovative business ideas')}
            </li>

            <li> {t('Providing support to professionals with various innovative technology and creative business ideas')}</li>
            <li>
            {t('Transferring recognition for effective and selected innovative technologies and creative business ideas')}
            </li>
            <li>{t('To support the organization of innovation technology cafe')}</li>
            <li>{t('Change of business registration of individual and business association')}</li>

            <li>{t('Science innovation to develop advanced innovation and STEM knowledge')}</li>
            <li>{t('To organize the technology that develops their knowledge in computational science')}</li>
          </ul>
        </>
      ),
      img: CourtProceduresImg,
    },

    {
      title: t('The main services provided by the information maintenance and renovation team'),
      description: (
        <>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
            <li>{t('Information technology equipment maintenance and repair')}</li>
            <li>{t('Level isupport/maintenance')}</li>
          </ul>
        </>
      ),
      img: CourtProceduresImg2,
    },
    {
      title: t('Software development team'),
      description: (
        <>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
            <li>
            {t('Software management')}
            </li>
            <li>
            {t('Website Portal Management')}
            </li>
            <li>
            {t('Database Management')}
            </li>
            
          </ul>
        </>
      ),
      img: LegalAssistanceImg,
    },
    // {
    //   title: t('Directorate of Informal Business'),
    //   description: (
    //     <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
    //       <li>{t('Directorate of Informal Business')}</li>
    //       {/* <li>e-Filing</li>
    //       <li>Payment of Fees</li>
    //       <li>Appointment Scheduling</li> */}
    //     </ul>
    //   ),
    //   img: OnlineServicesImg,
    // },
    // {
    //   title: t('Directorate of Consumer Protection'),
    //   description: (
    //     <>
    //       {/* <p className="text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
    //         We provide essential support services to ensure all individuals have
    //         equitable access to justice:
    //       </p> */}
    //       <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
    //         <li>
    //         {t('Receiving and responding to suggestions and complaints from the consumer community')}
    //         </li>
    //         {/* <li>
    //           Accessibility Services: Accommodations for individuals with
    //           disabilities to access court facilities and services.
    //         </li>
    //         <li>
    //           Victim Support: Counseling, advocacy, and resources for victims of
    //           crime to navigate the legal process.
    //         </li> */}
    //       </ul>
    //     </>
    //   ),
    //   img: SupportServicesImg,
    // },
    // {
    //   title: t('List of services provided by the Directorate of Development and Management of Shopping Centers'),
    //   description: (
    //     <>
    //       {/* <p className="text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
    //         Access a wide range of forms, documents, and legal publications to
    //         support your legal needs:
    //       </p> */}
    //       <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
    //         <li>
    //         {t('Ensuring that traders and farmers who come to the livestock market center for trading have permission to enter the market center.')}
    //         </li>
    //         <li>
    //        {t('Providing lodging services without entering the livestock market center for trading')}
    //         </li>
    //         {/* <li>
    //           Publications: Articles, guides, and legal resources for further
    //           reading.
    //         </li> */}
    //       </ul>
    //     </>
    //   ),
    //   img: DownloadsResourcesImg,
    // },
   
    
  ];

  return (
    <div className="services-page bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-200">
      <div className="relative shadow-2xl mb-8">
        <img
          src={HammerBanner}
          className="w-full h-40 object-cover"
          alt="Banner"
        />
        <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white text-4xl md:text-6xl font-bold">
          {t("services")}
        </h1>
      </div>

      {/* Introduction Section */}
      <section className="introduction bg-white dark:bg-gray-800 p-3 md:p-12 lg:p-5">
        <div data-aos="zoom-in" duration="1000">
          <p className="text-gray-700 dark:text-gray-300 leading-relaxed text-lg md:text-4xl text-center font-bold">
           {t('List of services we provide')}
          </p>
        </div>
      </section>

      {/* Service Sections */}
      {sections.map((section, index) => (
        <section
          key={index}
          className={`flex flex-col md:flex-row items-center ${
            index % 2 === 0
              ? "bg-gray-100 dark:bg-gray-900"
              : "bg-white dark:bg-gray-800"
          } p-6 md:p-12 lg:p-20`}
        >
          <div
            data-aos="fade-right"
            data-aos-duration="2000"
            className="w-full md:w-1/2 mb-6 md:mb-0"
          >
            <img
              src={section.img}
              className="w-full h-[50vh] rounded-lg shadow-lg object-cover"
              alt={`${section.title} Image`}
            />
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="3000"
            className="w-full md:w-1/2 md:pl-12"
          >
            <h2 className="text-2xl md:text-3xl font-bold text-gray-900 dark:text-gray-100 mb-4">
              {section.title}
            </h2>
            <div className="text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
              {section.description}
            </div>
          </div>
        </section>
      ))}
    </div>
  );
}

export default ServicesPage;
