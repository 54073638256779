import React,{useEffect} from "react";
import BannerImage from "../assets/images/BannerTwo.jpg"; // Renamed the image import
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from 'react-i18next';
import { t } from "i18next";

function Banner() {
  useEffect(() => {
    AOS.init({
      easing: 'ease-in-out',
      once: false,
      mirror: true,
    });
  }, []);

  return (
    <div className="relative">
      <img
        src={BannerImage}
        className="w-full h-[40vh] object-cover"
        alt="banner"
      />
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div data-aos="zoom-in" data-aos-duration="2000">
          <h1 className="text-white text-xl text-center md:text-4xl font-bold z-10">
          {t('Bole Sub-City Trade Office: Promoting Economic Resilience and Market Integrity')}
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Banner;
