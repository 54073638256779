import React from 'react';
import { useTranslation } from "react-i18next";
const CourtOperatingHours = () => {
  const { t, i18n } = useTranslation();
  const hours = [
    { day: t('Monday'), time: '2:30 AM - 11:00 PM LT' },
    { day: t('Tuesday'), time: '2:30 AM - 11:00 PM LT' },
    { day: t('Wednesday'), time: '2:30 AM - 11:00 PM LT' },
    { day: t('Thursday'), time: '2:30 AM - 11:00 PM LT' },
    { day: t('Friday'), time: '2:30 AM - 11:00 PM LT' },
    { day: t('Saturday'), time: t('Closed') },
    { day: t('Sunday'), time: t('Closed') },
  ];

  const holidays = [
    t('Ethiopian New Year'),
    t('Finding of the True Cross'),
    t('Ethiopian Christmas'),
    t('Ethiopian Epiphany'),
    t('Victory of Adwa Day'),
    t('Eid al-Fitr'),
    t('Ethiopian Easter'),
    t('Labour Day'),
    t('Ethiopian Patriots Victory'),
    t('Mawlid'),
  ];

  return (
    <div className="bg-white py-12 px-6 sm:px-12 lg:px-24">
      <h2 className="text-4xl lg:text-4xl font-bold text-center mb-12 text-blue-800">
        {t('Office Operating Hours')}
      </h2>
      <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-3">
        <div data-aos="fade-right" data-aos-duration="2000" className="bg-gray-50 p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold mb-4 text-blue-700">
          {t('Office Hours')}
          </h3>
          <ul className="list-disc list-inside text-lg text-gray-700">
            {hours.map((hour, index) => (
              <li key={index} className="mb-2">
                <span className="font-medium text-blue-800">{hour.day}:</span> {hour.time}
              </li>
            ))}
          </ul>
        </div>
        <div data-aos="zoom-in" data-aos-duration="2000" className="bg-gray-50 p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold mb-4 text-blue-700">
          {t('Days of Operation')}
          </h3>
          <ul className="list-disc list-inside text-lg text-gray-700">
            {hours.map((hour, index) => (
              <li key={index} className="mb-2">
                <span className="font-medium text-blue-800">{hour.day}:</span> {hour.time !== t('Closed') ?  t('Open') : t('Closed')}
              </li>
            ))}
          </ul>
        </div>
        <div data-aos="fade-left" data-aos-duration="2000" className="bg-gray-50 p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold mb-4 text-blue-700">
            {t('Holiday Closures')}
          </h3>
          <ul className="list-disc list-inside text-lg text-gray-700">
            {holidays.map((holiday, index) => (
              <li key={index} className="mb-2">{holiday}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CourtOperatingHours;
