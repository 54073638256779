import React from 'react';
import { useTranslation } from "react-i18next";
const AdditionalNewsSections = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className='w-full bg-gradient-to-r from-blue-500 to-teal-400'>

    <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 max-w-4xl mx-auto pt-4">
      {/* Section 1: Stay Informed */}
      <div data-aos="fade-right" className="p-6 bg-gradient-to-r from-yellow-400 to-orange-500 text-white rounded-lg shadow-lg text-center mb-10">
        <h2 className="text-4xl font-bold mb-4">{t('Stay Informed')}</h2>
        <p className="text-lg">
        {t('We bring you updates from every corner of the globe. Dive into the latest headlines and stay ahead with our comprehensive news coverage.')}
        </p>
      </div>

      {/* Section 2: Explore More */}
      <div data-aos="fade-left" className="p-6 bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded-lg shadow-lg text-center mb-10">
        <h2 className="text-4xl font-bold mb-4">{t('Explore More')}</h2>
        <p className="text-lg">
        {t('Discover in-depth analyses, opinion pieces, and exclusive stories that matter. Your curiosity fuels our commitment to delivering quality journalism.')}
        </p>
      </div>
    </div>
    </div>
  );
};

export default AdditionalNewsSections;
