import React, { useState } from "react";
import {
  FaHome,
  FaComments,
  FaNewspaper,
  FaBullhorn,
  FaImages,
  FaUserTie,
  FaInfoCircle,
  FaSignOutAlt,
} from "react-icons/fa";
import Users from "./Users";
import AddNews from "./AddNews";
import AddDirectorMessage from "./AddDirectorMessage";
import ImageUpload from "./ImageUploader";
import LoginForm from "./LoginForm";
import Officials from "./AddOfficials";
import AboutA from "./AboutA";
import AdminWelcomePage from "./AdminWelcomePage";
import AddAnnouncement from "./AddAnnouncement";
import MessagesManager from "./MessagesManager";
import ComplaintsList from "./ComplaintsList";
// import AddProduct from "./AddProduct"


const NavbarA = () => {
  const [currentPage, setCurrentPage] = useState("home"); // State to track current page
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Function to handle navigation to different pages
  const navigateToPage = (page) => {
    setCurrentPage(page);
  };

  // Function to handle login
  const handleLogin = (username, password) => {
    // Perform login logic here (e.g., API call, authentication)
    if (username === "hawasani" && password === "hawa@sani") {
      setIsAuthenticated(true);
    } else {
      alert("Invalid credentials");
    }
  };

  // Function to handle logout
  const handleLogout = () => {
    setIsAuthenticated(false);
    setCurrentPage("home");
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen md:h-auto overflow-y-auto">
      {!isAuthenticated ? (
        <LoginForm onLogin={handleLogin} />
      ) : (
        <>
          {/* Sidebar */}
          <aside className="bg-gray-900 text-gray-100 border-r shadow-md flex flex-col justify-between">
            <div>
              <div className="flex items-center h-16 border-b px-4">
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "mainhome"
                      ? "text-red-500 bg-gray-800"
                      : "text-white hover:bg-gray-800"
                  }`}
                  onClick={() => navigateToPage("mainhome")}
                >
                  <FaHome className="mr-2" /> Home Page
                </a>
              </div>
              <nav className="flex flex-col mt-4 md:w-[250px]">
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "home"
                      ? "text-red-500 bg-gray-800"
                      : "text-white hover:bg-gray-800"
                  }`}
                  onClick={() => navigateToPage("home")}
                >
                  <FaComments className="mr-2" /> User Feedback
                </a>
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "news"
                      ? "text-red-500 bg-gray-800"
                      : "text-white hover:bg-gray-800"
                  }`}
                  onClick={() => navigateToPage("news")}
                >
                  <FaNewspaper className="mr-2" /> Add News
                </a>
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "directormessage"
                      ? "text-red-500 bg-gray-800"
                      : "text-white hover:bg-gray-800"
                  }`}
                  onClick={() => navigateToPage("directormessage")}
                >
                  <FaBullhorn className="mr-2" /> Add Director Message
                </a>
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "announcements"
                      ? "text-red-500 bg-gray-800"
                      : "text-white hover:bg-gray-800"
                  }`}
                  onClick={() => navigateToPage("announcements")}
                >
                  <FaBullhorn className="mr-2" /> Add Announcement
                </a>
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "gallery"
                      ? "text-red-500 bg-gray-800"
                      : "text-white hover:bg-gray-800"
                  }`}
                  onClick={() => navigateToPage("gallery")}
                >
                  <FaImages className="mr-2" /> Add Image Gallery
                </a>
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "officials"
                      ? "text-red-500 bg-gray-800"
                      : "text-white hover:bg-gray-800"
                  }`}
                  onClick={() => navigateToPage("officials")}
                >
                  <FaUserTie className="mr-2" /> Add Officials
                </a>
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "urgentmessage"
                      ? "text-red-500 bg-gray-800"
                      : "text-white hover:bg-gray-800"
                  }`}
                  onClick={() => navigateToPage("urgentmessage")}
                >
                  <FaUserTie className="mr-2" /> Add Urgent Message
                </a>
                <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "complains"
                      ? "text-red-500 bg-gray-800"
                      : "text-white hover:bg-gray-800"
                  }`}
                  onClick={() => navigateToPage("complains")}
                >
                  <FaInfoCircle className="mr-2" /> View Complains
                </a>


                {/* <a
                  href="#"
                  className={`flex items-center px-6 py-3 text-lg font-semibold ${
                    currentPage === "products"
                      ? "text-red-500 bg-gray-800"
                      : "text-white hover:bg-gray-800"
                  }`}
                  onClick={() => navigateToPage("products")}
                >
                  <FaInfoCircle className="mr-2" /> Add Product
                </a> */}
              </nav>
            </div>
            <button
              onClick={handleLogout}
              className="text-white text-sm bg-red-600 px-4 py-2 rounded mt-4 mx-4 mb-20 hover:bg-red-700 self-center flex items-center"
            >
              <FaSignOutAlt className="mr-2" /> Logout
            </button>
          </aside>

          {/* Main Content */}
          <main className="flex-1 bg-gray-100">
            {/* Conditional rendering based on currentPage state */}

            {currentPage === "mainhome" && <AdminWelcomePage />}
            {currentPage === "home" && <Users />}
            {currentPage === "news" && <AddNews />}
            {currentPage === "directormessage" && <AddDirectorMessage />}
            {currentPage === "announcements" && <AddAnnouncement />}
            {currentPage === "gallery" && <ImageUpload />}
            {currentPage === "officials" && <Officials />}
            {currentPage === "about" && <AboutA />}
            {currentPage === "urgentmessage" && <MessagesManager/>}
            {currentPage === "complains" && <ComplaintsList/>}
            {/* {currentPage === "products" && <AddProduct/>} */}
          </main>
        </>
      )}
    </div>
  );
};

export default NavbarA;