import React from "react";
import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import IMG01 from "../assets/qqq.png";
import IMG02 from "../assets/adad.png";
import IMG03 from "../assets/Mube mube.png";
import IMG033 from "../assets/ff.png";
import IMG0333 from "../assets/Yemane Desalegne.png";
import IMG044 from "../assets/Girum Abitew.png";
import IMG055 from "../assets/Mihiretu Desalegne.png";
import IMG066 from "../assets/Dr Tulu Tilahun.png";
import IMG003 from "../assets/gengen.png";
import LOGO from "../assets/ITDB2.png";
import { useTranslation } from "react-i18next";

const contentStyle = "relative h-[75vh] w-full overflow-hidden bg-gray-800 text-center";
const imageStyle = "w-full h-full object-cover";
const textStyle = "absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-50 text-white  px-4 text-3xl font-bold rounded text-sm md:text-xl";

const App = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <div className="flex justify-center items-center bg-slate-600">
      <div className="w-1/6 text-center text-white px-4">
        <p className="text-white font-bold text-xl">
        Kirkos Subcity Innovation Technology Development Office
        </p>
        <p className="text-white font-bold text-xl">
          Welcome!
        </p>
      </div>
      <div className="w-4/6">
        <Carousel
          autoplay
          dotPosition="bottom"
          effect="scrollx"
          arrows
          prevArrow={<LeftOutlined className="custom-arrow custom-prev-arrow" />}
          nextArrow={<RightOutlined className="custom-arrow custom-next-arrow" />}
          autoplaySpeed={3000} // Set the timing to 3000 milliseconds (3 seconds)
          className="h-[75vh]"
        >
          <div className={contentStyle}>
            <img className={imageStyle} src={IMG01} alt="Image 1" />
            <div className={textStyle}>
              <p>Prime Minister Doctor Abiy Ahmed</p>
              <p>Prime Minister of Ethiopia</p>
            </div>
          </div>

          <div className={contentStyle}>
            <img className={imageStyle} src={IMG02} alt="Image 2" />
            <div className={textStyle}>
              <p>Ms. Adanech Abebe</p>
              <p>Addis Ababa City Mayor</p>
            </div>
          </div>

          <div className={contentStyle}>
            <img className={imageStyle} src={IMG03} alt="Image 3" />
            <div className={textStyle}>
              <p>Ms. Mubarek Kemal </p>
              <p>Chief Executive Officer of Kirkos Sub City</p>
            </div>
          </div>

          <div className={contentStyle}>
            <img className={imageStyle} src={IMG033} alt="Image 3" />
            <div className={textStyle}>
              <p>Ms. Solomon Amare </p>
              <p>Innovation Technology Bureau Head</p>
            </div>
          </div>

          <div className={contentStyle}>
            <img className={imageStyle} src={IMG0333} alt="Image 3" />
            <div className={textStyle}>
              <p>Ms. Yemane Desalegne </p>
              <p>Innovation Technology Bureau </p>
            </div>
          </div>

          
          <div className={contentStyle}>
            <img className={imageStyle} src={IMG055} alt="Image 3" />
            <div className={textStyle}>
              <p>Ms. Mihiretu Desalegne</p>
              <p>Innovation Technology </p>
            </div>
          </div>

          <div className={contentStyle}>
            <img className={imageStyle} src={IMG066} alt="Image 3" />
            <div className={textStyle}>
              <p>Dr Tulu Tilahun</p>
              <p>Innovation Technology </p>
            </div>
          </div>

          <div className={contentStyle}>
            <img className={imageStyle} src={IMG044} alt="Image 3" />
            <div className={textStyle}>
              <p>Ms. Girum Abitew </p>
              <p>Innovation Technology Bureau</p>
            </div>
          </div>

          <div className={contentStyle}>
            <img className={imageStyle} src={IMG003} alt="Image 3" />
            <div className={textStyle}>
              <p>Ms. Genet Dugassa</p>
              <p>Head of Kirkos Sub City Innovation Development Office</p>
            </div>
          </div>

         


          <div className={contentStyle}>
            <img
              className="w-full object-fill h-[80vh]"
              src={LOGO}
              alt="Image 7"
            />
            <div className={textStyle}>
              <p>Our Office Logo</p>
            </div>
          </div>
        </Carousel>
      </div>
      <div className="w-1/6 text-center text-white px-4">
        <p className="font-bold text-xl text-white">በቂርቆስ ክፍለ ከተማ የኢኖቬሽን ቴክኖሎጂ ልማት ጽ/ቤት</p>
        <p className="font-bold text-xl text-white">እንኳን ደህና መጡ።</p>
      </div>
    </div>
  );
}

export default App;
