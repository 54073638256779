import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function FAQ() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  const { t } = useTranslation();

  return (
    <div>
      <section className="bg-[#1D1D1D] text-gray-100 py-32 min-h-screen">
        <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
          <h2 className="mb-12 text-4xl font-bold leading text-center sm:text-5xl" data-aos="fade-up">
            {t('Frequently Asked Questions')}
          </h2>
          <div className="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 divide-gray-700">
            <details data-aos="fade-right">
              <summary className="py-2 outline-none cursor-pointer">
                {t('What services does the Kirkos Subcity Innovation Technology Development Office provide?')}
              </summary>
              <div className="px-4 pb-4">
                <p>
                  {t('We provide comprehensive IT support, networking solutions, and technological training for various bureaus within the Kirkos Subcity Administration.')}
                </p>
              </div>
            </details>
            <details data-aos="fade-left">
              <summary className="py-2 outline-none cursor-pointer">
                {t('How can I apply for IT services from the Kirkos Subcity Innovation Technology Development Office?')}
              </summary>
              <div className="px-4 pb-4">
                <p>
                  {t('You can request IT services by contacting our office and completing the required application process.')}
                </p>
              </div>
            </details>
            <details data-aos="fade-right">
              <summary className="py-2 outline-none cursor-pointer">
                {t('What role does the Kirkos Subcity Innovation Technology Development Office play in ensuring technological compliance?')}
              </summary>
              <div className="px-4 pb-4">
                <p>
                  {t('We monitor technological compliance, ensure data security, and promote best practices in IT within the Kirkos Subcity Administration.')}
                </p>
              </div>
            </details>
            <details data-aos="fade-left">
              <summary className="py-2 outline-none cursor-pointer">
                {t('How does the Kirkos Subcity Innovation Technology Development Office support local innovation?')}
              </summary>
              <div className="px-4 pb-4">
                <p>
                  {t('We foster local innovation by collaborating with tech enthusiasts, providing advisory services, and facilitating access to cutting-edge technologies.')}
                </p>
              </div>
            </details>
            <details data-aos="fade-right">
              <summary className="py-2 outline-none cursor-pointer">
                {t('What are the benefits of IT training programs offered by the Kirkos Subcity Innovation Technology Development Office?')}
              </summary>
              <div className="px-4 pb-4">
                <p>
                  {t('Our IT training programs enhance technical skills, improve productivity, and equip staff with the latest technological knowledge necessary for efficient operations.')}
                </p>
              </div>
            </details>
            <details data-aos="fade-left">
              <summary className="py-2 outline-none cursor-pointer">
                {t('How does the Kirkos Subcity Innovation Technology Development Office contribute to cybersecurity within the subcity administration?')}
              </summary>
              <div className="px-4 pb-4">
                <p>
                  {t('We implement robust cybersecurity measures, conduct regular audits, and provide guidance to ensure secure handling of sensitive information across all bureaus.')}
                </p>
              </div>
            </details>
            <details data-aos="fade-right">
              <summary className="py-2 outline-none cursor-pointer">
                {t('What steps does the Kirkos Subcity Innovation Technology Development Office take to promote sustainable technology practices?')}
              </summary>
              <div className="px-4 pb-4">
                <p>
                  {t('We advocate for eco-friendly technology solutions, promote energy-efficient practices, and encourage the adoption of sustainable IT infrastructure within the subcity administration.')}
                </p>
              </div>
            </details>
            <details data-aos="fade-left">
              <summary className="py-2 outline-none cursor-pointer">
                {t('How can local businesses benefit from partnering with the Kirkos Subcity Innovation Technology Development Office?')}
              </summary>
              <div className="px-4 pb-4">
                <p>
                  {t('Local businesses can benefit from enhanced technological support, access to innovative solutions, and opportunities for digital transformation that improve competitiveness and efficiency.')}
                </p>
              </div>
            </details>
            <details data-aos="fade-right">
              <summary className="py-2 outline-none cursor-pointer">
                {t('What resources does the Kirkos Subcity Innovation Technology Development Office provide for IT project management?')}
              </summary>
              <div className="px-4 pb-4">
                <p>
                  {t('We offer project management expertise, consultation services, and tools to help oversee and execute IT projects effectively within the Kirkos Subcity Administration.')}
                </p>
              </div>
            </details>
            <details data-aos="fade-left">
              <summary className="py-2 outline-none cursor-pointer">
                {t('How does the Kirkos Subcity Innovation Technology Development Office ensure transparency and accountability in its operations?')}
              </summary>
              <div className="px-4 pb-4">
                <p>
                  {t('We uphold transparency by adhering to regulatory standards, maintaining clear communication channels, and regularly reporting on our IT activities and achievements.')}
                </p>
              </div>
            </details>
            <details data-aos="fade-right">
              <summary className="py-2 outline-none cursor-pointer">
                {t('How does the Kirkos Subcity Innovation Technology Development Office monitor technological trends and innovations?')}
              </summary>
              <div className="px-4 pb-4">
                <p>
                  {t('We stay updated on technological advancements, collaborate with industry leaders, and conduct research to implement cutting-edge solutions within the Kirkos Subcity Administration.')}
                </p>
              </div>
            </details>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FAQ;
