import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Space, message, Modal, Form, Upload } from "antd";
import {
  ExclamationCircleOutlined,
  EyeOutlined,
  UploadOutlined,
} from "@ant-design/icons";

const ImageUpload = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(4); // Number of items per page
  const { confirm } = Modal;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [updatingImageId, setUpdatingImageId] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get("https://kirkosinnovation.api.codenilesolutions.com/api/images");
      setImageList(response.data);
    } catch (error) {
      console.error("Error fetching images:", error);
      message.error("Failed to fetch images. Please try again later.");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    if (!selectedImage) return;

    const formData = new FormData();
    formData.append("image", selectedImage);

    try {
      const response = await axios.post(
        "https://kirkosinnovation.api.codenilesolutions.com/api/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("Image uploaded successfully");
      fetchImages(); // Refresh image list after uploading
      setPreview(null);
      setSelectedImage(null);
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error("Failed to upload image. Please try again later.");
    }
  };

  const handleDelete = async (id) => {
    confirm({
      title: "Are you sure you want to delete this image?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          await axios.delete(`https://kirkosinnovation.api.codenilesolutions.com/api/images/${id}`);
          message.success("Image deleted successfully");
          fetchImages(); // Refresh image list after deletion
        } catch (error) {
          console.error("Error deleting image:", error);
          message.error("Failed to delete image. Please try again later.");
        }
      },
    });
  };

  const handleView = (imageUrl) => {
    setCurrentImage(imageUrl);
    setIsModalVisible(true);
  };

  const handleUpdate = (id) => {
    setUpdatingImageId(id);
    setIsUpdateModalVisible(true);
  };

  const handleUpdateImageChange = (file) => {
    setSelectedImage(file.file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file.file);
  };

  const handleUpdateImage = async () => {
    if (!selectedImage) return;

    const formData = new FormData();
    formData.append("image", selectedImage);

    try {
      const response = await axios.put(
        `https://kirkosinnovation.api.codenilesolutions.com/api/images/${updatingImageId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("Image updated successfully");
      fetchImages(); // Refresh image list after updating
      setIsUpdateModalVisible(false);
      setPreview(null);
      setSelectedImage(null);
    } catch (error) {
      console.error("Error updating image:", error);
      message.error("Failed to update image. Please try again later.");
    }
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (imageUrl) => (
        <img
          src={`https://kirkosinnovation.api.codenilesolutions.com${imageUrl}`}
          alt="Uploaded"
          style={{ width: "100px", height: "100px", objectFit: "cover" }}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="default"
            className="bg-blue-600 p-2 text-white"
            icon={<EyeOutlined />}
            onClick={() => handleView(record.imageUrl)}
          >
            View
          </Button>
          <Button
            type="default"
            className="bg-green-700 p-2 text-white"
            icon={<UploadOutlined />}
            onClick={() => handleUpdate(record._id)}
          >
            Update
          </Button>
          <Button
            type="default"
            className="bg-red-600 p-2 text-white"
            onClick={() => handleDelete(record._id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: imageList.length,
    onChange: (page) => setCurrentPage(page),
    showSizeChanger: false,
  };

  return (
    <div className="flex flex-col w-full gap-4 bg-white shadow-md rounded-md">
      <div className=" p-4">
        <h2 className="text-2xl font-bold mb-4  text-blue-800">Upload Image</h2>
        <div className="flex items-center justify-center w-full h-64 bg-gray-100 rounded-md overflow-hidden">
          {preview ? (
            <img
              src={preview}
              alt="Uploaded"
              className="object-cover w-full h-full"
            />
          ) : (
            <p className="text-gray-500">No image selected</p>
          )}
        </div>
        <div className="mt-4">
          <label
            htmlFor="imageUpload"
            className="block text-sm font-medium text-gray-700"
          >
            Choose an image:
          </label>
          <input
            type="file"
            id="imageUpload"
            accept="image/*"
            onChange={handleImageChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>
        <button
          onClick={handleUpload}
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Upload Image
        </button>
      </div>

      <div className=" p-4">
        <h2 className="text-2xl font-bold mb-4">Uploaded Images</h2>
        <Table
          columns={columns}
          dataSource={imageList}
          pagination={paginationConfig}
          rowKey="_id"
        />
      </div>

      <Modal
        title="View Image"
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <img
          src={`https://kirkosinnovation.api.codenilesolutions.com${currentImage}`}
          alt="Uploaded"
          style={{ width: "100%", height: "auto" }}
        />
      </Modal>

      <Modal
        title="Update Image"
        visible={isUpdateModalVisible}
        onOk={handleUpdateImage}
        onCancel={() => setIsUpdateModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="image"
            label="Choose a new image"
            valuePropName="file"
          >
            <Upload
              beforeUpload={() => false}
              onChange={handleUpdateImageChange}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>Select Image</Button>
            </Upload>
          </Form.Item>
          {preview && (
            <img
              src={preview}
              alt="Updated"
              style={{ width: "100%", height: "auto", marginTop: "16px" }}
            />
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default ImageUpload;
