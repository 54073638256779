import React from "react";
import { Button } from "antd";
import Gallery from "../Components/Gallery";
import { useTranslation } from "react-i18next";
function GallaryApp() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Gallery />
      <div>
        <div className="bg-gradient-to-r from-blue-400 to-blue-600 py-12 text-center text-white">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-extrabold mb-6">{t('Contact us for any Issue')}</h2>
            <p className="text-lg mb-8">
             {t('vibrant community')}
             </p>
            <div className="flex justify-center space-x-4">
              <div className="w-24 h-24 rounded-full overflow-hidden shadow-lg transform transition duration-300 hover:scale-105">
                <img
                  src="https://via.placeholder.com/150"
                  alt="Community 1"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-24 h-24 rounded-full overflow-hidden shadow-lg transform transition duration-300 hover:scale-105">
                <img
                  src="https://via.placeholder.com/150"
                  alt="Community 2"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-24 h-24 rounded-full overflow-hidden shadow-lg transform transition duration-300 hover:scale-105">
                <img
                  src="https://via.placeholder.com/150"
                  alt="Community 3"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-24 h-24 rounded-full overflow-hidden shadow-lg transform transition duration-300 hover:scale-105">
                <img
                  src="https://via.placeholder.com/150"
                  alt="Community 4"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="mt-8">
              <a
                href="/contact"
                className="inline-block bg-white text-blue-600 font-bold py-3 px-6 rounded-full shadow-lg hover:bg-gray-100 transition duration-300"
              >
               {t('contact us')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GallaryApp;
