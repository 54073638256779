import React from "react";
import CarouselEffect from "../Components/CarouselEffect";
import CFooter from "../Components/CFooter";
import StandFor from "../Components/StandFor"
import Banner from "../Components/Banner";
import Contact from "../Components/Contact";
import DirectorMessage from "../Components/DirectorMessage";
import NewsHero from "../Components/News/NewsHero"
import { useTranslation } from 'react-i18next';
import HomeContactus from "../Components/HomeContactus"

import OurPartners from "../Components/OurPartners"
function Home() {
  const { t, i18n } = useTranslation();

 const righttext="Judicial Independence and Integrity: The Supreme Court of Oromia is committed to upholding judicial independence and integrity, ensuring that justice is administered fairly and impartially to all individuals within the Oromia region. It plays a crucial role in interpreting laws and delivering justice without external influences, reflecting the region's commitment to the rule of law."
 const  lefttext="Promotion of Legal Access and Transparency: The Supreme Court of Oromia strives to enhance access to justice and legal transparency for the citizens of the region. Through various reforms and initiatives, the court aims to make legal processes more understandable and accessible, thereby fostering public trust and confidence in the judicial system."

  return (
    <div className="relative">
     <CarouselEffect/>  
     <CFooter/>
     <StandFor/>
     <Banner/>
     <DirectorMessage/>
     <NewsHero/>
     <HomeContactus/>
     <OurPartners/>
    </div>
  );
}

export default Home;