import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const EnjoyNews = () => {
  React.useEffect(() => {
    AOS.init({
      easing: "ease-in-out",
      once: false,
      mirror: true,
      duration: 2000,
    });
  }, []);
  const { t, i18n } = useTranslation();
  return (
    <div className="bg-gradient-to-r from-blue-500 to-teal-400 py-12 px-6 rounded-lg shadow-lg mt-8">
      <div   className="max-w-4xl mx-auto text-center ">
        
        <div data-aos="fade-down">
          <h2 className="text-4xl font-extrabold text-white mb-4" data-aos="fade-down">
          {t('Enjoy Our News')}
        </h2>
        </div>
        <p className="text-lg text-white mb-6" data-aos="fade-up" data-aos-delay="200">
        {t('Stay updated with the latest news and updates. We bring you the most recent and important news stories from around Our Office. Thank you for being a valued reader.')}
        </p>
        {/* <div className="mt-8">
          <img
            src="https://readymadeui.com/Imagination.webp"
            alt="News enjoyment"
            className="w-full h-auto rounded-lg shadow-lg"
            data-aos="zoom-in"
          />
        </div> */}
        <p className="text-xl text-white mt-6 font-semibold" data-aos="fade-up" data-aos-delay="400">
        {t('Thank you for watching news with us.')}
        </p>
      </div>
    </div>
  );
};

export default EnjoyNews;
