import React from 'react'
import NewsHero from "../Components/News/NewsHero"
import { useTranslation } from 'react-i18next';
import HammerBanner from "../assets/images/tradeb.jpg"
import EnjoyNews from '../Components/News/EnjoyNews';
import AdditionalNewsSections from '../Components/News/AdditionalNewsSections ';
function News() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div>
      <div className="relative shadow-2xl mb-8">
          <img
            src={HammerBanner}
            className="w-full h-40  "
            alt="Banner"
          />
          <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white text-4xl md:text-6xl font-bold">
           {t('news')}
          </h1>
        </div>
      </div>
      <div>
      <NewsHero/>
      <EnjoyNews/>
      <AdditionalNewsSections/>
      </div>
     
    </div>
  )
}

export default News