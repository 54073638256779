import React, { useEffect, useState } from "react";
import axios from "axios";
import { Image } from "antd";
import HammerBanner from "../assets/images/tradeb.jpg";
import { useTranslation } from "react-i18next";
const Officials = () => {
  const { t, i18n } = useTranslation();
  const [officials, setOfficials] = useState([]);

  useEffect(() => {
    fetchOfficials();
  }, []);

  const fetchOfficials = async () => {
    try {
      const response = await axios.get("https://kirkosinnovation.api.codenilesolutions.com/api/officials");
      setOfficials(response.data);
    } catch (error) {
      console.error("Error fetching officials:", error);
    }
  };

  return (
    <div>
      <div className="relative shadow-2xl mb-8">
        <img
          src={HammerBanner}
          className="w-full h-40 object-cover"
          alt="Banner"
        />
        <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white text-4xl md:text-6xl font-bold">
        {t('Leadership Team')}
        </h1>
      </div>
      <div className="min-h-auto pb-5 bg-gray-100 pt-5">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {officials.map((official, index) => (
              <div
                key={official._id}
                className="bg-white overflow-hidden shadow-lg rounded-lg"
              >
              <img
                src={`https://kirkosinnovation.api.codenilesolutions.com/${official.imageURL}`}
                alt={official.title}
                className="w-full h-60 object-fit rounded-t-md"
              />
                {/* <Image
                  alt={`officials-${index + 1}`}
                  className="object-cover rounded-lg h-96 w-full "
                  height={300}
                  src={`http://localhost:3000${official.imageURL}`} // Ensure the full URL is correct
                  // style={{ width: "150%", height: "300px" }}
                  // Set fixed height for all images
                /> */}

                <div className="p-4">
                  <h3 className="text-xl font-bold text-center text-gray-800">
                    {official.name}
                  </h3>
                  <p className="text-gray-600 mb-4 text-center font-bold">
                    {official.title}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
       
      </div>
      <div className="w-full ">
          <div class="bg-gray-800 font-sans min-h-[350px] relative mx-auto rounded overflow-hidden">
            <div class="grid lg:grid-cols-2 w-full h-full absolute inset-0">
              <div class="p-4 max-lg:hidden ">
                <img
                  src="https://readymadeui.com/image-1.webp"
                  className="object-cover w-full h-full"
                  alt="img"
                />
              </div>

              <div class="flex flex-col items-end justify-center text-right p-8 relative bg-[#262464] rounded-tl-[206px] z-20 before:absolute before:inset-0 before:!left-auto before:bg-[#293587] before:w-2/3 before:rounded-bl-[206px] before:-z-10">
                <h3 class="font-bold sm:text-4xl text-2xl text-yellow-400">
                {t('Connect with Our Exceptional Teams')}
                </h3>
                <p class="text-sm text-gray-300 mt-4 p-3">
                {t('Exceptinal Team Description')}
                </p>

                <button
                  type="button"
                  class="bg-yellow-400 hover:bg-yellow-500 text-gray-800 py-4 px-8 font-semibold rounded w-max mt-8"
                >
                  <a href="/contact">{t('contact us')}</a>
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Officials;
