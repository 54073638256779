import React,{useEffect} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Insa from "../assets/images/INSA.jpg"
import Tele from "../assets/images/Tele.jpeg"
import ITDB from "../assets/images/ITDBLOGO.jpg"
import MIT from "../assets/images/MIT.png"
import SM from "../assets/images/ITDBLOGO.jpg"
import Gage from "../assets/images/Gage.png"
import Admas from "../assets/images/Admas.png"
import Riftvalley from "../assets/images/Rift Valley.jpg"
import AOS from "aos";
import "aos/dist/aos.css";

import { useTranslation } from 'react-i18next';


const contentStyle = {
  height: '200px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#1e293b',
  color: '#fff',
  borderRight: '3px solid #fff',

};

const imageStyle = {
  width: '200px',
  height: '200px',
  objectFit: 'cover',
  margin: 'auto',
};

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};


function App() {
  useEffect(() => {
    AOS.init({
      easing: "ease-in-out",
      once: false,
      mirror: true,
      duration:2000,
    });
  }, []);
  const { t, i18n } = useTranslation();
  return (
    <div className='pt-20   border-3 '>
    <div
    data-aos="fade-down"
    
    ><h2 style={{ textAlign: 'center', marginBottom: '20px' }} className='text-blue-800 font-bold text-5xl'>{t('Our Partners')}</h2></div>
    <Carousel
    className=''
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={5000}
      keyBoardControl={true}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      itemClass="carousel-item-padding-40-px"
    >
      <div style={contentStyle}>
        <a href='https://insa.gov.et/web/en' target="_blank">
          <img src={Insa} style={imageStyle} alt="INSA" className='rounded-lg p-4' />
        </a>
        <h3 className='text-white text-2xl font-bold'>INSA</h3>
      </div>
      <div style={contentStyle}>
        <a href='https://www.ethiotelecom.et/' target="_blank">
          <img src={Tele} style={imageStyle} alt="INSA" className='rounded-lg p-4' />
        </a>
        <h3 className='text-white text-2xl font-bold'>Tele</h3>
      </div>
      
      <div style={contentStyle}>
        <a href='https://www.aaitdb.gov.et/' target="_blank">
          <img src={ITDB} style={imageStyle} alt="ITDB" className='rounded-lg p-4' />
        </a>
        <h3 className='text-white text-2xl font-bold'>ITDB</h3>
      </div>

      <div style={contentStyle}>
        <a href='https://www.mint.gov.et/?lang=en' target="_blank">
          <img src={MIT} style={imageStyle} alt="MIT" className='rounded-lg p-4' />
        </a>
        <h3 className='text-white text-2xl font-bold'>MIT</h3>
      </div>

      <div style={contentStyle}>
        <a href='https://www.gagecollege.net/elearning/' target="_blank">
          <img src={Gage} style={imageStyle} alt="Gage" className='rounded-lg p-4' />
        </a>
        <h3 className='text-white text-2xl font-bold'>Gage University</h3>
      </div>

      <div style={contentStyle}>
        <a href='https://admasuniversity.edu.et/' target="_blank">
          <img src={Admas} style={imageStyle} alt="Admas" className='rounded-lg p-4' />
        </a>
        <h3 className='text-white text-2xl font-bold'>Admas University</h3>
      </div>

      <div style={contentStyle}>
        <a href='https://riftvalleyuniversity.org/'  target="_blank">
          <img src={Riftvalley} style={imageStyle} alt="Riftvalley" className='rounded-lg p-4' />
        </a>
        <h3 className='text-white text-2xl font-bold'>Riftvalley University</h3>
      </div>
    </Carousel>
  </div>
  )
}

export default App


