import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import LOGO from "../assets/images/ITDBLOGO.jpg";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
import { Select } from "antd";
import ContinuedText from "../Components/ContinuedText"

const { Option } = Select;

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const changeLanguage = (value) => {
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    AOS.init({
      easing: "ease-in-out",
      once: false,
      mirror: true,
    });
  }, []);

  useEffect(() => {
    // This effect runs whenever the language changes
  }, [i18n.language]);

  return (
  
    <div
      className={`sticky top-0 z-50 ${isScrolled ? "shadow-lg " : "shadow-md"}`}
    >
      <ContinuedText/>
      <header
        className={`flex py-4 px-4 sm:px-10 bg-blue-800  font-[sans-serif] tracking-wide transition-all duration-300 ${
          isScrolled ? "py-2" : "py-4"
        }`}
      >
        <div className="flex flex-wrap items-center justify-between gap-5 w-full">
          <div className="animate-jump-in animate-infinite animate-duration-[9000ms] animate-delay-1000 animate-ease-in-out">
            <NavLink to="/" className="flex items-center ">
              <img
                src={LOGO}
                alt="logo"
                className={`transition-all duration-300 ${
                  isScrolled ? "w-16 h-16" : "w-20 h-20"
                }`}
              />
            </NavLink>
          </div>
          {/* Hamburger Icon for Mobile */}
          <button onClick={toggleMenu} className="lg:hidden">
            <svg
              className="w-7 h-7 bg-white text-white"
              fill="#000"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          {/* Mobile Menu */}
          <div className={`lg:hidden ${isMenuOpen ? "block" : "hidden"}`}>
            <ul className="lg:flex gap-x-5 max-lg:space-y-3 max-lg:fixed max-lg:bg-white max-lg:w-1/2 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:p-6 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto z-50">
              <li className="mb-6 hidden max-lg:block">
                <NavLink to="/" className="flex items-center">
                  <img src={LOGO} alt="logo" className="w-36" />
                </NavLink>
              </li>
              <li className="max-lg:border-b border-gray-300 max-lg:py-3 px-3">
                <NavLink
                  exact
                  to="/"
                  activeClassName="text-[#007bff]"
                  className="hover:text-[#007bff] text-gray-500 block font-semibold text-[15px]"
                  onClick={closeMenu}
                >
                  {t("home")}
                </NavLink>
              </li>
              <li className="max-lg:border-b border-gray-300 max-lg:py-3 px-3">
                <NavLink
                  to="/about"
                  activeClassName="text-[#007bff]"
                  className="hover:text-[#007bff] text-gray-500 block font-semibold text-[15px]"
                  onClick={closeMenu}
                >
                  {t("about us")}
                </NavLink>
              </li>
              <li className="max-lg:border-b border-gray-300 max-lg:py-3 px-3">
                <NavLink
                  to="/services"
                  activeClassName="text-[#007bff]"
                  className="hover:text-[#007bff] text-gray-500 block font-semibold text-[15px]"
                  onClick={closeMenu}
                >
                  {t("services")}
                </NavLink>
              </li>
              <li className="max-lg:border-b border-gray-300 max-lg:py-3 px-3">
                <NavLink
                  to="/news"
                  activeClassName="text-[#007bff]"
                  className="hover:text-[#007bff] text-gray-500 block font-semibold text-[15px]"
                  onClick={closeMenu}
                >
                  {t("news")}
                </NavLink>
              </li>
              <li className="max-lg:border-b border-gray-300 max-lg:py-3 px-3">
                <NavLink
                  to="/announcements"
                  activeClassName="text-[#007bff]"
                  className="hover:text-[#007bff] text-gray-500 block font-semibold text-[15px]"
                  onClick={closeMenu}
                >
                  {t("announcements")}
                </NavLink>
              </li>
              <li className="max-lg:border-b border-gray-300 max-lg:py-3 px-3">
                <NavLink
                  to="/officials"
                  activeClassName="text-[#007bff]"
                  className="hover:text-[#007bff] text-gray-500 block font-semibold text-[15px]"
                  onClick={closeMenu}
                >
                  {t("officials")}
                </NavLink>
              </li>
              <li className="max-lg:border-b border-gray-300 max-lg:py-3 px-3">
                <NavLink
                  to="/contact"
                  activeClassName="text-[#007bff]"
                  className="hover:text-[#007bff] text-gray-500 block font-semibold text-[15px]"
                  onClick={closeMenu}
                >
                  {t("contact us")}
                </NavLink>
              </li>
              
              {/* <li className="max-lg:border-b border-gray-300 max-lg:py-3 px-3">
                <NavLink
                  to="/marketprice"
                  activeClassName="text-[#007bff]"
                  className="hover:text-[#007bff] text-gray-500 block font-semibold text-[15px]"
                  onClick={closeMenu}
                >
                  {t("Market Price")}
                </NavLink>
              </li> */}
              <li className="max-lg:border-b border-gray-300 max-lg:py-3 px-3">
                <NavLink
                  to="/complaint"
                  activeClassName="text-[#007bff]"
                  className="hover:text-[#007bff] text-gray-500 block font-semibold text-[15px]"
                  onClick={closeMenu}
                >
                  {t("Complaint")}
                </NavLink>
              </li>
              <li className="max-lg:border-b border-gray-300 max-lg:py-3 px-3">
                <NavLink
                  to="/gallery"
                  activeClassName="text-[#007bff]"
                  className="hover:text-[#007bff] text-gray-500 block font-semibold text-[15px]"
                  onClick={closeMenu}
                >
                  {t("gallery")}
                </NavLink>
              </li>
            </ul>
          </div>
          {/* Regular Menu - Hidden on Small Screens */}
          <div className="hidden lg:flex flex-grow justify-center items-center gap-x-5">
            <NavLink
              to="/"
              className={({ isActive, isPending }) =>
                isPending
                  ? "text-yellow-600"
                  : isActive
                  ? "text-yellow-500 font-bold "
                  : "text-white"
              }
            >
              {t("home")}
            </NavLink>
            <NavLink
              to="/about"
              className={({ isActive, isPending }) =>
                isPending
                  ? "text-yellow-600"
                  : isActive
                  ? "text-yellow-500 font-bold"
                  : "text-white"
              }
            >
              {t("about us")}
            </NavLink>
            <NavLink
              to="/services"
              className={({ isActive, isPending }) =>
                isPending
                  ? "text-yellow-600"
                  : isActive
                  ? "text-yellow-500 font-bold"
                  : "text-white"
              }
            >
              {t("services")}
            </NavLink>
            <NavLink
              to="/news"
              className={({ isActive, isPending }) =>
                isPending
                  ? "text-yellow-600 "
                  : isActive
                  ? "text-yellow-500 font-bold"
                  : "text-white"
              }
            >
              {t("news")}
            </NavLink>
            <NavLink
              to="/announcements"
              className={({ isActive, isPending }) =>
                isPending
                  ? "text-yellow-600 "
                  : isActive
                  ? "text-yellow-500 font-bold"
                  : "text-white"
              }
            >
              {t("announcements")}
            </NavLink>
            <NavLink
              to="/officials"
              className={({ isActive, isPending }) =>
                isPending
                  ? "text-yellow-600 "
                  : isActive
                  ? "text-yellow-500 font-bold"
                  : "text-white"
              }
            >
              {t("officials")}
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive, isPending }) =>
                isPending
                  ? "text-yellow-600 "
                  : isActive
                  ? "text-yellow-500 font-bold"
                  : "text-white"
              }
            >
              {t("contact us")}
            </NavLink>
             {/* <NavLink
              to="/marketprice"
              className={({ isActive, isPending }) =>
                isPending
                  ? "text-yellow-600 "
                  : isActive
                  ? "text-yellow-500 font-bold"
                  : "text-white"
              }
            >
              {t("Market Price")}
            </NavLink> */}
              
            <NavLink
              to="/complaint"
              className={({ isActive, isPending }) =>
                isPending
                  ? "text-yellow-600 "
                  : isActive
                  ? "text-yellow-500 font-bold"
                  : "text-white"
              }
            >
              {t("Complaint")}
            </NavLink>
            <NavLink
              to="/gallery"
              className={({ isActive, isPending }) =>
                isPending
                  ? "text-yellow-600 "
                  : isActive
                  ? "text-yellow-500 font-bold"
                  : "text-white"
              }
            >
              {t("gallery")}
            </NavLink>


          </div>
          {/* Language Selector */}
          <div className="flex max-lg:ml-auto items-center space-x-3">
            <span className="text-sm font-bold text-white">{t("LN")}</span>
            <Select
              defaultValue={i18n.language}
              value={i18n.language}
              onChange={changeLanguage}
              className="text-sm rounded-full font-bold transition-all ease-in-out duration-300 bg-blue-600 text-white hover:bg-blue-700 focus:border-blue-800"
              dropdownClassName="bg-white border border-blue-600 rounded-lg"
            >
              <Option className="p-2 hover:bg-blue-700" value="am">
                {t("Amharic")}
              </Option>
              <Option className="p-2 hover:bg-blue-700" value="en">
                {t("English")}
              </Option>
              
              <Option className="p-2 hover:bg-blue-700" value="or">
                {t("Afaan Oromo")}
              </Option>
             
            </Select>
          </div>
        </div>
      </header>
    </div>
  
  );
}

export default Navbar;
