import React from 'react'
import ServicesComp from "../Components/Services/Services"
import { useTranslation } from 'react-i18next';
import Features from '../Components/Features';
function Services() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div>
      <ServicesComp/>
      </div>
      <div >
      <Features/>
      </div>
     
    </div>
  )
}

export default Services