import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Pagination, Modal } from 'antd';

function Users() {
  const [messages, setMessages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5); // Number of items per page
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [viewModalVisible, setViewModalVisible] = useState(false);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get('https://kirkosinnovation.api.codenilesolutions.com/api/messages');
        const sortedMessages = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setMessages(sortedMessages);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleView = (message) => {
    setSelectedMessage(message);
    setViewModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://kirkosinnovation.api.codenilesolutions.com/api/messages/${id}`);
      setMessages(messages.filter((message) => message._id !== id));
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const truncateMessage = (message, maxLength = 70) => {
    return message.length > maxLength ? `${message.substring(0, maxLength)}...` : message;
  };

  const columns = [
    { title: 'First Name', dataIndex: 'firstName', key: 'firstName' },
    { title: 'Last Name', dataIndex: 'lastName', key: 'lastName' },
    { title: 'Phone Number', dataIndex: 'phoneNumber', key: 'phoneNumber' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Message', dataIndex: 'message', key: 'message', render: (text) => truncateMessage(text) },
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div className="flex">
          <Button type="primary" className="mr-2" onClick={() => handleView(record)}>View</Button>
          <Button type="button" className="bg-red-600 p-2 text-white" onClick={() => handleDelete(record._id)}>Delete</Button>
        </div>
      ),
    },
  ];

  const currentData = messages.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold text-blue-800 mb-6">Feedback from Users</h1>
      <Table
        dataSource={currentData}
        columns={columns}
        pagination={false}
        rowKey={(record) => record._id}
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={messages.length}
        onChange={handlePageChange}
        className="mt-4"
      />
      <Modal
        visible={viewModalVisible}
        title="Message Details"
        onCancel={() => setViewModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setViewModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        {selectedMessage && (
          <div>
            <p className="mb-2"><strong>First Name:</strong> {selectedMessage.firstName}</p>
            <p className="mb-2"><strong>Last Name:</strong> {selectedMessage.lastName}</p>
            <p className="mb-2"><strong>Phone Number:</strong> {selectedMessage.phoneNumber}</p>
            <p className="mb-2"><strong>Email:</strong> {selectedMessage.email}</p>
            <p className="mb-2"><strong>Message:</strong> {selectedMessage.message}</p>
            <p className="mb-2"><strong>Timestamp:</strong> {new Date(selectedMessage.timestamp).toLocaleString()}</p>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default Users;
