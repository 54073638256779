import React from 'react';
import genet from "../../assets/images/Genet Dugassa.png";
import nituh from "../../assets/images/Nituh.png";
import avatar1 from "../../assets/images/Human Icon.png";
import avatar2 from "../../assets/images/Human Icon.png";
import avatar3 from "../../assets/images/Human Icon.png";
import { useTranslation } from 'react-i18next';
function OrganizationChart() {
    
  const { t, i18n } = useTranslation();
  return (
    <div className="bg-gray-200 min-h-screen p-8">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
        <h1 className="text-3xl font-semibold text-center py-4 bg-slate-700 text-white">{t('Structure')}</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
          {/* Top level */}
          <div className="col-span-1 md:col-span-3">
          <MemberCard image={genet} name={t('Genet Dugassa')} role={t('Head of Innovation and Technology Development Office')} />
          </div>
          {/* Second level */}
          <div className="col-span-1 md:col-span-2 md:col-start-2 text-center pr-64">
            <MemberCard image={nituh} name={t('Nitsuh Ayele')} role={t('Coordinator of works')}/>
          </div>
          {/* Third level */}
          <MemberCard image={avatar1} name={t('Haymanot Temechachew')} role={t('Information technology maintenance and renewal team leader')}/>
          <MemberCard image={avatar2} name={t('Zelalem Teshale')} role={t('Information technology infrastructure data center team leader')} />
          <MemberCard image={avatar3} name={t('Mekdelawit Workneh')} role={t('Information technology training and capacity building team leader')} />
        </div>
      </div>
    </div>
  );
}

const MemberCard = ({ image, name, role }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden hover:bg-gray-100">
    <div className="p-4">
      <img className="w-20 h-20 md:w-24 md:h-24 rounded-full mx-auto mb-4" src={image} alt={name} />
      <h2 className="text-lg font-semibold text-center text-gray-800">{name}</h2>
      <p className="text-sm text-gray-600 text-center">{role}</p>
    </div>
  </div>
);

export default OrganizationChart;
